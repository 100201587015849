import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

import './layout.css';

interface Props {
  children: ReactNode;
}

const Layout = ({ children }: Props) => (
  <>
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
        padding: '0px 1.0875rem 1.45rem',
      }}>
      <main>{children}</main>
    </div>
  </>
);

console.log(`
(((((((                                                                                                                                                                  
  .@@@@       %@%                                                                                                                                                               
@@@@&      .@@     @@@@@@#                                                                                                                                                        
%@@@@       @@@@@@@           ,@@@@@@@@@                                                                                                                                               
@@.      &@@@@@@@@@@@  &@@@@@@            @@@@#                                                          @@@@@@@@@@@.                                                                    
@@.  @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%    @@@@@@@@@@@@@@                                            #@@@@       @@.         @@@@@@&                                                    
@@.      &@@@@@@@@@@@  &@@@@@@@@@@@@@@@@@@    .@@@@@@@@@@@@@@@@@@@@(  @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@    %@@  @@.    @@@@@      .@@@@@@@@@                                           
%@@@@    .@@@@@@&         @@@@@@@@@@@@@@@@                     @@@@@@@                          @@@@*    @@,    @@@@@@@@@,    @@            @@@@&                                      
@@. .@@@@@@&           @@@@@@@@@@@@@@  .@@@@@@@@@@@@@@@@  @@@@@    (@@@@@@@@@@@@@@@@@@@@,         @@@@@@@    %@@       @@@@  .@@@@@@@      .@@                                    
@@. .@@@@@@&         @@@@@@@@@@@@@@           *@@@@@@@@@    *@@  @@@@@@@@@@@@@@@@@@@@@@@@@@    #@@@@@@@@@@@       @@@@@@@@@@@&  @@@@@@@@@.    @@                                  
@@. .@@@@@@&       @@@@@@@@@@@@@%    @@#    @@@@@@@@@@@@@@       @@@@@@@         (@@@@@@,    @@@@@  @@@@@@@@@.      %@@@@@@       &@@@@@@@@%    @@%                               
@@. .@@@@@@&    &@@@@@@            @@     @@@@@@@  @@@@@@@         (@@         @@@@@@@     @@@@*      %@@@@@@@@@    %@@@@@@          @@@@@@@@@  @@%                               
@@. .@@@@@@&  @@@@&           @@@@@@@  .@@@@@@(      *@@@@@@(  @@            @@@@@@@    %@@@@     @@     @@@@@@@@@  %@@@@@@       &@@@@@@@@%    @@%                               
@@. .@@@@@@@@@@@@@@@@..  .%%%%@@@@@  ..,@@@@@@(  %%  ,%%@@@@(..  @@&%%  ..(@@@@%%,    %%@@@@@@@*..  %%,..  %%@@@@@%%@@@@@@@.....@@&%%%%     ..%%                                  
@@. .@@@@@@&,,@@@@@@@@@((/    @@@@@  @@@@@@@*,.         @@@@@@@  *,.  ((@@@@@,,       ((&@@@@@@@@@((  ,,*  ((@@@**  %@@@@@@*,*@@@@#((    ((/,*                                    
@@. .@@@@@@&    &@@@@@@@@@@&         @@@@@@@       @@@@@@@@@@@@@@  /@&@@@@/         @@@@@@@  @@@@@@@@@.  @@@@,      %@@@@@@     @@@@@@@    .&@                                    
@@. .@@@@@@&       @@@@@@@@@@@     @@@@@@@@@  *@@@@@@(    @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@    #@@@@@@@@@@@         %@@@@@@       @@@@@@@.    @@@@%                               
@@    .@@@@@@&         @@@@@@@@@@@%  @@@@@@@@@@@@@@       @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@,         @@@@@@@    %@@  @@@@@@@@@@@&       @@@@@@%      ,@@@@                           
%@@    &@@@@@@@@@@@  &@&    @@@@@@@@@@@@  @@@@@         @@(           @@@@@@@                     @@,    @@,    @@@@@                @@.    @@@@@@@         @@@@@@@@@#                 
@@.    @@@@@@@@@@@@@@@@.  @@    ,@@@@@@@@@@@@@#      *@@@@@@@@@@@@@@@@       @@@@/    @@@@@@@@@@@@@@@@,  @@     @@@@@@@@@@@@@@@@@@@@@@@@@@@@    &@@@@@@@@%             ,@@               
@@@@@                     @@@@     @@@@@@@@@@@@@@@@              @@@@@@@         /@@  @@@@@       @@@@*      %@@@@@@@@@                     @@       @@@@@@@@@     @@     @@             
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#    @@@@@@@@@@@@@@@@@@@@@                   @@       @@*           @@@@@@@@@@@@@@                            @@@@%         @@@@@@@    ,@@               
%@@                         @@@@@@@     @@@@@@@@@@@@@@@@@@@@@@@       @@@@@    /@@@@@@*           @@,                                           .@@@@@@%           @@#                 
                       ,@@@@@@%         @@@@@@@@@@@@@@@@@@@@@@@@@/    @@@@@                                                                     ,@@@@@@@@@@@                    
                            @@@@@@@@@#           @@@@@@@@@@@@       @@@@/                                                                                                       
                                 @@@@@@@@@@@@@@                @@@@@                                             *   /@   &    ,                      (  &% ( .,                
                                               @@@@@@@@@@@@@@@@                                                                                                                                                                                                                                                                                                 
®39!5
`);

export default Layout;
